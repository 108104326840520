<template>
  <div :class="$style.container">
    <div :class="$style.row">
      <slot name="before" />
      <div :class="$style.title">
        <div :class="$style.text">{{ title || t('title') }}</div>
      </div>
      <slot name="after" />
    </div>
    <slot />
  </div>
</template>

<i18n>
{
  "en": {
    "title": "Nothing here yet"
  },
  "ru": {
    "title": "Ничего нет"
  }
}
</i18n>

<script setup>
const { t } = useI18n()

defineOptions({
  name: 'CommonZeroData'
})

defineProps({
  title: {
    type: String,
    default: ''
  }
})
</script>
<style module lang="scss">
.container {
  width: 100%;
  padding: 5em;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  border-radius: 1em;
  text-align: center;
}
.row {
  display: flex;
  align-items: center;
  justify-content: center;
  & + * {
    margin-top: 2em;
  }
}
.title {
  margin-right: 0.5em;
  .text {
    font-weight: 500;
    font-size: 1.6em;
    color: rgba(255, 255, 255, 0.7);
  }
}
</style>
